import '../scss/site.scss';
import $ from 'cash-dom';

import axios from 'axios';

const HTTP = axios.create({
  xsrfHeaderName: "X-CSRFToken",
  xsrfCookieName: "csrftoken"
});

const ready = fn => {
  if (document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
};

window.$ = $;
window.ready = ready;

const calculateTotal = () => {
  const ticket = $('input[name=ticket]:checked').parent().find('.ticket--price').data('price');
  const donation = $("input[name=donation]").val() * 100;
  const itemTotal = $(".sale-item input").get().reduce((m, el) => {
    const id = $(el)
      .attr("name")
      .replace("_qty", "")
      .replace("sale_item_", "sale-item-");
    const qty = $(el).val();
    const price = $(`#${id}`).data("price");
    m += (qty * price);
    return m;
  }, 0);
  if ((ticket + donation + itemTotal) > 0) {
    $('#total-charge').parent().addClass('has-charge');
  } else {
    $('#total-charge').parent().removeClass('has-charge');
  }
  $('#total-charge').text((ticket + donation + itemTotal) / 100);

  const submitButton = $("#submit-button");
  submitButton.attr("disabled", true);
  const paymentIntentInput = $("input[name=paymentIntentId]");
  const form = $('form');

  HTTP.post("/api/payment-intents/", {
    amount: ticket + donation + itemTotal,
    description: `${form.data('event-title')} Registration`,
    paymentIntentId: paymentIntentInput.val(),
  }).then(resp => {
    paymentIntentInput.val(resp.data.paymentIntentId);
    if (resp.data.paymentIntentId !== null) {
      submitButton.attr("disabled", null);
    }
    form.data('client-secret', resp.data.clientSecret);
    console.log(form.data('client-secret'));
  });
};

const calculatePathTotal = (e) => {
  const checked = $('input.course-check[type=checkbox]:checked');
  const amounts = checked.get().map(e => {
    return $('#select-period-' + e.value).get().map(s => {
      return parseInt(s.options[s.selectedIndex].dataset.amount);
    }).flat();
  }).flat();
  const amount = amounts.reduce((i, a) => i += a, 0);

  if (amount > 0) {
    $("#total-amount").text(` for $${amount / 100}`);
  } else {
    $("#total-amount").text('');
  }

  const submitButton = $("#submit-button");
  submitButton.attr("disabled", true);

  if (amount > 0) {
    const paymentIntentInput = $("input[name=paymentIntentId]");
    const form = $('form');

    HTTP.post("/api/payment-intents/", {
      amount,
      description: 'Path Course Registration',
      paymentIntentId: paymentIntentInput.val(),
    }).then(resp => {
      paymentIntentInput.val(resp.data.paymentIntentId);
      if (resp.data.paymentIntentId !== null) {
        submitButton.attr("disabled", null);
      }
      form.data('client-secret', resp.data.clientSecret);
    });
  }
};

ready(() => {
  $('input[name=ticket]').on('change', calculateTotal);
  $('.sale-item input').on('change', calculateTotal);
  $('input[name=donation]').on('change', calculateTotal);
  $('input.course-check[type=checkbox]').on('change', calculatePathTotal);
  $('.path-badge--courses select.custom-select').on('change', calculatePathTotal);
});
